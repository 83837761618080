/* src/App.css */
body {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#sb-navigation {
  width: 240px; /* Adjust width as needed */
  flex-shrink: 0;
  z-index: 10; /* Ensure the navigation bar is above other content */
}

.container {
  flex-grow: 1;
  margin-left: 240px; /* Adjust margin to match the width of the LeftNavigation */
  overflow-y: auto; /* Ensure content is scrollable if it overflows */
  padding: 0; /* Remove padding */
}

.home-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.top-image {
  width: 100%;
  height: 100vh; /* Full viewport height */
  background-image: url('../public/top-image.jpeg');
  background-size: cover;
  background-position: center;
  position: relative;
}

.image-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 37, 38, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.white-text {
  color: white;
}

.second-section {
  display: flex;
  flex-direction: row;
  padding: 2em;
  background-color: white;
  text-align: left;
  position: relative;
  z-index: 1; /* Ensure the second section is above other content */
}

.second-section .text-content {
  flex: 1;
  padding-right: 2em;
}

.second-section .image-box {
  flex: 1;
  height: auto;
  background-size: cover;
  background-position: center;
  z-index: 2;
}

.green-text {
  color: green;
}

.third-section {
  background-color: #d2e0d6;
  padding: 2em;
  text-align: center;
}

.black-text {
  color: black;
}

@media (max-width: 768px) {
  #sb-navigation {
    width: 60px; /* Adjust width for smaller screens */
  }

  .container {
    margin-left: 60px; /* Adjust margin for smaller screens */
  }

  .home-container h1 {
    font-size: 6vw; /* Adjust font size for smaller screens */
  }

  .second-section {
    flex-direction: column;
    text-align: center;
  }

  .second-section .text-content {
    padding-right: 0;
  }

  .second-section .image-box {
    margin-top: 2em;
  }
}

@media (min-width: 769px) {
  .home-container h1 {
    font-size: 4vw; /* Adjust font size for larger screens */
  }
}